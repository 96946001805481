import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import GooglePublisherTagLandscapeAdSlot from 'components/AdSlots/GooglePublisherTagLandscapeAdSlot';
import OfferCard from 'components/Timeline/OfferCard';

import OFFER from 'constants/offer';

const OfferGridCardWithAd = dynamic(
  () =>
    import('components/Timeline/OfferCard/OfferGridCard/OfferGridCardWithAd')
);

const HomeOfferCard = ({
  activeGoogleAd = null,
  gaEventCategory,
  offer,
  offersViewMode = null,
  offerImagePriority = false,
}) => (
  <>
    {offer.cardAds.length > 0 &&
      offersViewMode === OFFER.VIEW_MODES.GRID &&
      offer.cardAds.map((cardAd) => (
        <OfferGridCardWithAd
          key={cardAd.id}
          gaEventCategory={gaEventCategory}
          {...cardAd}
        />
      ))}
    {activeGoogleAd && (
      <div
        className={twJoin(
          'col-start-1',
          offersViewMode === OFFER.VIEW_MODES.LIST
            ? 'col-end-1 row-start-10'
            : 'col-end-1 row-start-10 md:col-end-4 md:row-start-4 lg:col-end-5 lg:row-start-3',
          activeGoogleAd?.className
        )}
      >
        <GooglePublisherTagLandscapeAdSlot
          adId={activeGoogleAd.adId}
          adPath={activeGoogleAd.adPath}
          showAdSlotOnBreakpoints={activeGoogleAd.showAdSlotOnBreakpoints}
        />
      </div>
    )}
    <OfferCard
      className="border border-neutral-high-300 dark:border-neutral-low-300 lg:border-none"
      gaEventCategory={gaEventCategory}
      isListView={offersViewMode === OFFER.VIEW_MODES.LIST}
      offerImagePriority={offerImagePriority}
      showFilterCategoriesDropdown
      {...offer}
    />
  </>
);

HomeOfferCard.propTypes = {
  activeGoogleAd: PropTypes.shape({
    adId: PropTypes.string.isRequired,
    adPath: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    showAdSlotOnBreakpoints: PropTypes.array,
  }),
  gaEventCategory: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    categoryId: PropTypes.number,
    categoryName: PropTypes.string,
    categorySlug: PropTypes.string,
    key: PropTypes.string.isRequired,
    offerComments: PropTypes.number.isRequired,
    offerId: PropTypes.number.isRequired,
    offerIsHighlight: PropTypes.bool.isRequired,
    ratings: PropTypes.shape().isRequired,
    offerOldPrice: PropTypes.number,
    offerPhoto: PropTypes.string.isRequired,
    offerPrice: PropTypes.number.isRequired,
    offerPriceType: PropTypes.string.isRequired,
    offerPublished: PropTypes.string.isRequired,
    offerSlug: PropTypes.string.isRequired,
    offerStatusName: PropTypes.string.isRequired,
    offerTags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ),
    offerTitle: PropTypes.string.isRequired,
    storeDomain: PropTypes.string.isRequired,
    storeId: PropTypes.number,
    storeImage: PropTypes.string,
    storeName: PropTypes.string,
    subcategoryId: PropTypes.number,
    subcategoryName: PropTypes.string,
    subcategorySlug: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    userName: PropTypes.string.isRequired,
    userPhoto: PropTypes.string.isRequired,
    userTypeName: PropTypes.string.isRequired,
    userUsername: PropTypes.string.isRequired,
  }).isRequired,
  offersViewMode: PropTypes.string,
  offerImagePriority: PropTypes.bool,
};

export default memo(HomeOfferCard);
